import React, { useMemo } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import useAppSetting from "contexts/AppSettingsContext";
import { AuthenticationScreen } from "screens/AuthenticationScreen";
import NotFound from "screens/NotFoundScreen";
import SplashScreen from "screens/SplashScreen";
import { useFleetViewVisibility } from "shared-hooks/visibility-hooks/use-fleet-view-visibility";
import { useLDFlags } from "shared-hooks/use-ld-flags";
import { useWayfinderSettingsVisibility } from "shared-hooks/visibility-hooks/use-wayfinder-settings-visibility";
import {
  ALL_ROUTE_EXPLORER_SIDEBAR_PATHS,
  BASE_VOYAGE_SCREEN_PATHS,
  FLEET_VIEW_PATH,
  PRINT_PATH,
  SETTINGS_PATHS,
  VOYAGE_BASE_PATH,
  VOYAGE_PLAN_PATH,
  ROUTE_EXPLORER_ROUTE_COMPARISON_PATH,
  COMMON_VESSEL_DETAILS_PATHS,
  ADHERENCE_PATH,
  PERFORMANCE_PATH,
} from "shared-hooks/use-wayfinder-url";
import { useVesselDetailsAvailableTabs } from "components/sidebar/VesselDetails/use-vessel-details-available-tabs";
import { VoyagePlanRedirectLoadingScreen } from "./VoyagePlanRedirectLoadingScreen";
import { WayfinderRedirects } from "./WayfinderRedirects";

// Bundle splitting time. Split out screens that may not be used
const VoyageScreen = React.lazy(() => import("screens/VoyageScreen"));
const PrintScreen = React.lazy(() => import("screens/PrintScreen"));

const AppSettingsScreen = React.lazy(() => import("screens/AppSettingsScreen"));

const AppRoutes: React.FC<{
  enablePrint: boolean;
  allowFleetView: boolean;
  showGuidancePage: boolean;
  showVesselPerformancePage: boolean;
  voyageScreen: JSX.Element;
  showSettings: boolean;
  showRouteExplorer: boolean;
}> = ({
  enablePrint,
  voyageScreen,
  allowFleetView,
  showGuidancePage,
  showVesselPerformancePage,
  showSettings,
  showRouteExplorer,
}) => {
  // add on paths for features with controlled access
  const enabledPaths = useMemo(() => {
    const paths = [...BASE_VOYAGE_SCREEN_PATHS, ...COMMON_VESSEL_DETAILS_PATHS];
    if (allowFleetView) {
      paths.push(FLEET_VIEW_PATH);
    }
    if (showGuidancePage) {
      paths.push(ADHERENCE_PATH);
    }
    if (showVesselPerformancePage) {
      paths.push(PERFORMANCE_PATH);
    }
    if (showSettings) {
      paths.push(...SETTINGS_PATHS);
    }
    if (showRouteExplorer) {
      paths.push(...ALL_ROUTE_EXPLORER_SIDEBAR_PATHS);
    } else {
      // all users need to see plans in the explorer comparison view
      paths.push(ROUTE_EXPLORER_ROUTE_COMPARISON_PATH);
    }
    return paths;
  }, [
    allowFleetView,
    showGuidancePage,
    showVesselPerformancePage,
    showSettings,
    showRouteExplorer,
  ]);
  return (
    <Switch>
      <Route exact path={enabledPaths}>
        {voyageScreen}
      </Route>
      <Route exact path={`${VOYAGE_BASE_PATH}${PRINT_PATH}`}>
        {enablePrint && <PrintScreen />}
      </Route>
      <Redirect to="/not-found" />
    </Switch>
  );
};

export const RouterSwitch = () => {
  const showSettings = useWayfinderSettingsVisibility();
  const allowFleetView = useFleetViewVisibility();
  const { showRouteExplorer } = useLDFlags();
  const {
    showGuidancePage,
    showVesselPerformancePage,
  } = useVesselDetailsAvailableTabs();

  const { value: enablePrint } = useAppSetting("enablePrint");

  // keep the voyage screen around so we are not needlessly destroying it
  const voyageScreen = useMemo(() => <VoyageScreen />, []);

  const routerProps = useMemo(
    () => ({
      allowFleetView,
      enablePrint,
      voyageScreen,
      showGuidancePage,
      showVesselPerformancePage,
      showSettings,
      showRouteExplorer,
    }),
    [
      allowFleetView,
      enablePrint,
      showGuidancePage,
      showVesselPerformancePage,
      voyageScreen,
      showSettings,
      showRouteExplorer,
    ]
  );

  return (
    <>
      <WayfinderRedirects />
      <Switch>
        <Route path="/" exact>
          <SplashScreen />
        </Route>
        <Route path={VOYAGE_PLAN_PATH} exact>
          <VoyagePlanRedirectLoadingScreen />
        </Route>
        <Route path="/authenticate">
          <AuthenticationScreen />
        </Route>
        <Route path="/settings">
          <AppSettingsScreen />
        </Route>
        <Route path="/not-found" exact>
          <NotFound />
        </Route>
        {/* Routes to screens that implement app features */}
        <AppRoutes {...routerProps} />
      </Switch>
    </>
  );
};
