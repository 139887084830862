import { feature } from "@turf/helpers";
import config from "config";
import WeatherContext from "contexts/WeatherContext";
import { MultiPolygon } from "geojson";
import { slicePolygonsToSingleWorldCopies } from "helpers/geometry";
import { getBufferedRoute, unnestMultiPolygons } from "helpers/routes";
import _ from "lodash";
import { useContext, useEffect } from "react";
import { MapBounds } from "shared-types";
import { Route } from "shared-types/RouteTypes";

/**
 * Transform map bounds to geometry that can be used for the region of interest
 * @param bounds
 * @returns
 */
export const getRoiGeometryFromBounds = (
  mapBounds: MapBounds
): MultiPolygon => {
  const bounds = mapBounds.bounds;
  return unnestMultiPolygons(
    slicePolygonsToSingleWorldCopies({
      type: "Polygon",
      coordinates: [
        [
          [bounds[0], bounds[1]],
          [bounds[2], bounds[1]],
          [bounds[2], bounds[3]],
          [bounds[0], bounds[3]],
          [bounds[0], bounds[1]],
        ],
      ],
    }).features
  ).geometry;
};

/**
 * Handle all voyage-screen updates to the region of interest from one spot, so that
 * we do not inadvertantly implement competing updates or race conditions.
 *
 * If no bounds and no route are provided, do nothing
 * If the newly computed ROI is not different than the current ROI, do nothing
 *
 * @param readyToSetMapRoi - is the app in a stable state, when an update is safe?
 * @param routesForRoi - use these routes for the update
 * @param fallbackBoundsForRoi - fallback to these bounds if there is no route
 */
export const useMapRoiUpdater = (
  readyToSetMapRoi: boolean,
  routesForRoi: Route[],
  fallbackBoundsForRoi: MapBounds | undefined
) => {
  const { setRegionOfInterest, currentForecastMetadata } = useContext(
    WeatherContext
  );
  useEffect(() => {
    if (!currentForecastMetadata.initialized || !readyToSetMapRoi) {
      return;
    }

    let geometry;

    if (routesForRoi.length > 0) {
      const polygons = routesForRoi.map((route) =>
        getBufferedRoute(route, config.weatherBufferDistanceKm)
      );
      const combinedPolygon = unnestMultiPolygons(polygons);
      geometry = combinedPolygon.geometry;
    } else if (fallbackBoundsForRoi) {
      geometry = getRoiGeometryFromBounds(fallbackBoundsForRoi);
    }

    if (
      geometry &&
      !_.isEqual(
        // do not bother to set if nothing has changed
        currentForecastMetadata.regionOfInterest?.coordinates,
        geometry.coordinates
      )
    ) {
      setRegionOfInterest(geometry);
    }

    // Exclude changes to the `setRegionOfInterest` function from triggering the effect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fallbackBoundsForRoi,
    currentForecastMetadata.initialized,
    routesForRoi,
    readyToSetMapRoi,
  ]);
};
